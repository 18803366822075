/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import emailjs from "@emailjs/browser";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => emailjs.init("63B7oQgvqOLsUqJZJ"), []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await emailjs.send("service_w6ir3fh", "template_izvoswb", {
        name,
        email,
        message: message + " from " + email,
      });
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
    // try {
    //   await axios.post(
    //     "https://us-central1-testtme-tarot-soru-cevap.cloudfunctions.net/api/sendMessage",
    //     {
    //       name,
    //       email,
    //       message,
    //     }
    //   );
    //   alert("Email sent!");
    // } catch (err) {
    //   alert(err);
    // }
  };
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={10}
          lg={6}
          sx={{ mx: "auto", mb: { xs: 0, md: 6 }, textAlign: "center" }}
        >
          <MKTypography variant="h3" mb={1}>
            Sorunlarınızı çözmek için buradayız
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Uygulama ile ilgili herhangi bir sorun veya öneriniz var ise bize buradan
            ulaşabilirsiniz.
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
          <MKBox onSubmit={handleSubmit} width="100%" component="form" autoComplete="off">
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKInput
                    variant="standard"
                    label="İsim"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    type="email"
                    variant="standard"
                    label="Email"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    placeholder="Mesajınızı yazın"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                    rows={6}
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={6}>
                <MKButton type="submit" variant="gradient" color="dark">
                  Mesajı gönder
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactUs;
