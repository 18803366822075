import { useEffect } from "react";

function AdComponent() {
  useEffect(() => {
    try {
      if (localStorage.getItem("cookieConsent") === "true") {
        // Üçüncü taraf çerezleri içeren script'i yükle
        const script = document.createElement("script");
        script.src =
          "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8928208932702579";
        script.async = true;
        document.head.appendChild(script);
      }

      (window.adsbygoogle = window.adsbygoogle || []).push({
        google_ad_client: "ca-pub-8928208932702579",
        enable_page_level_ads: true,
      });
    } catch (e) {
      console.error("AdSense error: ", e);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-8928208932702579"
      data-ad-slot="2464485652"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
}

export default AdComponent;
